import React from "react";
import "./../../styles/components/inicio/Formulario.css";
import { useForm, ValidationError } from "@formspree/react";
function ContactForm() {
  const [state, handleSubmit] = useForm("mgedbkzp");
  if (state.succeeded) {
    return <p>Thanks for joining!</p>;
  }
  return (
    <div className="form-container">
    <form onSubmit={handleSubmit}>
      <div className="form-group">
      <label htmlFor="full-name">Nombre</label>
      <input
        type="text"
        name="name"
        id="full-name"
        placeholder="Nombre Completo"
        required=""
        className="input-distribuidor"
      ></input>
    </div>
    <div className="form-group">
  <label htmlFor="phone">Teléfono</label>
  <input
    id="phone"
    type="tel"
    name="phone"
    className="input-distribuidor"
    placeholder="Número de teléfono"
  />
  </div>
    <div className="form-group">
      <label htmlFor="email">Email</label>
      <input
        id="email"
        type="email"
        name="email"
        className="input-distribuidor"
        placeholder="Dirección de e-mail"

      />
      <ValidationError prefix="Email" field="email" errors={state.errors} />
    </div>

    <div className="form-group">
  <label htmlFor="city">Ciudad</label>
  <input
    type="text"
    name="city"
    placeholder="Ciudad"
    required=""
    className="input-distribuidor"
  />
</div>

<div className="form-group">
<label htmlFor="message">Mensaje</label>
  <textarea id="message" name="message" className="input-distribuidor" />
  <ValidationError prefix="Message" field="message" errors={state.errors} />
</div>

<button type="submit" disabled={state.submitting} className="input-distribuidor">
  Enviar
</button>
    
    </form>
    </div>

  );
}
function Formulario() {
  return <ContactForm />;
}
export default Formulario;
